import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const HeadingWrapperTitle = styled.p`
  font-weight: 700;
  font-size: 25px;
  line-height: 27px;
  color: rgb(255, 255, 255);
  text-shadow: rgb(134 104 223) 0px 4px 20px;
  margin-top: 40px;
  margin-bottom: 33px;
  font-family: Poppins;
`;

export const Panel = styled(Box)`
  width: 100%;
  padding-top: 16px;
  flex-direction: column;
  gap: 16px;
  background-color: rgba(43, 27, 78, 0.4);
  border-radius: 24px;
  border: 1px solid rgb(56, 50, 65);
  padding: 0;
  tr {
    border-bottom: 1px solid rgb(56, 50, 65);
    :last-child {
      border-bottom: none;
    }
  }
`;

export const HeadCellLabelTableInfo = styled(Box)`
  color: rgb(119, 126, 144);
  font-weight: 400;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  font-family: Inter;
`;

export const BoxIconSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 5px;
`;

export const TokenTableParagraph = styled.p`
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
`;
export const InfoPaginations = styled(Box)`
  display: flex;
  justify-content: center;
`
